.docs{
    cursor: pointer;
}

.aiii{
    /* border: 1px solid #000; */
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;

}

.files_wrapper{
    margin: 1.5cm;
}
